define("shop/controllers/products/product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentImage: 0,
    session: Ember.inject.service(),
    media: Ember.inject.service(),
    product: Ember.computed.readOnly('model.product'),
    variants: Ember.computed.readOnly('model.variants'),
    products: Ember.computed.readOnly('model.products'),
    imageSorting: Ember.A(['isPerson:desc']),
    images: Ember.computed.filter('product.mainImages', function (image) {
      if (!image.isPerson) {
        return true;
      }

      var gender = this.session.gender || 'women';
      return image.gender == gender;
    }),
    sortedImages: Ember.computed.sort('images', 'imageSorting'),
    actions: {
      showCart: function showCart() {
        this.transitionToRoute({
          queryParams: {
            cart: true
          }
        });
      }
    }
  });

  _exports.default = _default;
});