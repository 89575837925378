define("shop/templates/components/product-header/variants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DbA0w8Ka",
    "block": "{\"symbols\":[\"variant\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-wrap items-center my-4\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"variant\",[24,[\"variants\"]]],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"replace\",\"class\",\"activeClass\",\"route\",\"model\"],[true,\"block p-px rounded-full border border-transparent mr-2 mb-2 relative hover:border-grey transition\",\"border-grey\",\"products.product\",[23,1,[\"variantSlug\"]]]],{\"statements\":[[0,\"      \"],[7,\"img\",true],[11,\"src\",[29,[\"/variants/\",[23,1,[\"variantSlug\"]],\".png\"]]],[10,\"class\",\"block w-6 h-6 m-px rounded-full\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[]],[24,[\"product\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"absolute pin flex items-center justify-center text-white\"],[8],[0,\"\\n          \"],[1,[28,\"svg-jar\",[\"check\"],[[\"width\",\"height\",\"class\"],[\"12\",\"12\",\"fill-current block\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/product-header/variants.hbs"
    }
  });

  _exports.default = _default;
});