define("shop/controllers/accessories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    queryParams: ['all'],
    all: false,
    productSorting: Ember.A(['position:asc']),
    products: Ember.computed.readOnly('model.products'),
    sortedProducts: Ember.computed.sort('products', 'productSorting'),
    category: Ember.computed.readOnly('model.category')
  });

  _exports.default = _default;
});