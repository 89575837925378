define("shop/routes/products/product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    intl: Ember.inject.service(),
    routerScroll: Ember.inject.service(),
    beforeModel: function beforeModel(_ref) {
      var from = _ref.from,
          to = _ref.to;

      this._super.apply(this, arguments);

      this.routerScroll.set('preserveScrollPosition', from && to && from.params.title == to.params.title);
    },
    model: function model(_ref2) {
      var title = _ref2.title,
          variant = _ref2.variant;

      var _this$modelFor = this.modelFor('products'),
          products = _this$modelFor.products;

      var variants = products.filterBy('titleSlug', title);
      var product = variants.findBy('variantSlug', variant);
      return {
        product: product,
        variants: variants,
        products: products
      };
    },
    headTags: function headTags() {
      var model = this.modelFor(this.routeName);

      if (!Ember.isPresent(model)) {
        return;
      }

      var product = model.product;
      var description = this.session.locale == 'ro' ? product.descriptionRo : product.descriptionEn;
      return [{
        type: 'meta',
        tagId: 'description',
        attrs: {
          name: 'description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'og-title',
        attrs: {
          property: 'og:title',
          content: this.intl.t('product.title', {
            title: product.title,
            variant: product.variant,
            category: product.categorySlug
          })
        }
      }, {
        type: 'meta',
        tagId: 'og-description',
        attrs: {
          property: 'og:description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'og-type',
        attrs: {
          property: 'og:type',
          content: 'product'
        }
      }, {
        type: 'meta',
        tagId: 'og-image',
        attrs: {
          property: 'og:image',
          content: product.image && product.image.originalUrl
        }
      }, {
        type: 'meta',
        tagId: 'og-image-width',
        attrs: {
          property: 'og:image:width',
          content: 1200
        }
      }, {
        type: 'meta',
        tagId: 'og-image-height',
        attrs: {
          property: 'og:image:height',
          content: 1500
        }
      }, {
        type: 'meta',
        tagId: 'product-brand',
        attrs: {
          property: 'product:brand',
          content: this.intl.t('shop.name')
        }
      }, {
        type: 'meta',
        tagId: 'product-availability',
        attrs: {
          property: 'product:availability',
          content: 'in stock'
        }
      }, {
        type: 'meta',
        tagId: 'product-condition',
        attrs: {
          property: 'product:condition',
          content: 'new'
        }
      }, {
        type: 'meta',
        tagId: 'product-price-amount',
        attrs: {
          property: 'product:price:amount',
          content: product.price
        }
      }, {
        type: 'meta',
        tagId: 'product-price-currency',
        attrs: {
          property: 'product:price:currency',
          content: 'EUR'
        }
      }, {
        type: 'meta',
        tagId: 'product-retailer-item-id',
        attrs: {
          property: 'product:retailer_item_id',
          content: product.id
        }
      }];
    },
    setupController: function setupController(controller, _ref3) {
      var product = _ref3.product;

      this._super.apply(this, arguments);

      this.trackViewContentEvent(product);
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      controller.set('surchargesArVisible', false);

      if (isExiting) {
        this.routerScroll.set('preserveScrollPosition', false);
        controller.set('currentImage', 0);
      }
    },
    trackViewContentEvent: function trackViewContentEvent(product) {
      this.metrics.trackEvent('FacebookPixel', {
        event: 'ViewContent',
        content_ids: [product.get('id')],
        content_type: 'product',
        currency: this.session.get('shop.currency'),
        value: product.get('exchangedPrice')
      });
      this.metrics.trackEvent('GoogleAnalytics', {
        event: 'view_item',
        currency: this.session.get('shop.currency'),
        value: product.get('exchangedPrice'),
        items: [{
          item_id: product.get('id'),
          item_name: product.get('title'),
          item_variant: product.get('variant')
        }]
      });
    },
    redirect: function redirect(_ref4) {
      var product = _ref4.product;

      if (!Ember.isPresent(product)) {
        this.transitionTo('shop');
      }
    }
  });

  _exports.default = _default;
});